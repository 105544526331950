import cn from 'classnames';
import styles from './Rotate.module.scss';
import { Logo } from '@/src/components/Logo';

export const Rotate = () => {
  return (
    <div className={cn(styles.rotate, 'bg')}>
      <div className={cn(styles.content_container, 'container')}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <p className={styles.text}>
          Для удобства игры нужно перевернуть телефон
        </p>
      </div>
    </div>
  );
};
