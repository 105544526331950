import cn from 'classnames';

import styles from './Worth.module.scss';
import { VoteLink } from '@/src/components/VoteLink';
import sendEventToCounters from '@/src/counterEvents';

// type TProps = {};

export const Worth = () => {
  return (
    <div className={styles.worth}>
      <h2 className={cn(styles.worth_title, 'title')}>
        Считаешь, что мы решаем достойные задачи?
      </h2>
      <VoteLink
        text="Поддержи нас в&#160;Рейтинге работодателей&#160;hh.ru"
        classNames={[styles.vote, 'btn_secondary']}
        onClick={() =>
          sendEventToCounters({ action: 'landing-page', label: 'to-vote' })
        }
      />
    </div>
  );
};
