import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';
import styles from './QuoteMendeleev.module.scss';
import photoImage from './images/photo.png';
import { useGameContext } from '@/src/pages/Game';

type TProps = {
  quoteText?: string;
  currentStepName: string;
  isVisible: boolean;
  isVisibleFinish?: boolean;
  currentFinishComponent?: () => React.JSX.Element;
  onClickNext?: () => void;
  withoutQuote?: boolean;
};

export const QuoteMendeleev = ({
  quoteText,
  currentStepName,
  isVisible,
  isVisibleFinish = false,
  currentFinishComponent,
  onClickNext,
  withoutQuote,
}: TProps) => {
  const gameContext = useGameContext();

  const handleClickNext = () => {
    if (currentStepName === 'bottleCorrect') {
      gameContext?.setCurrentStepName('bottleLoadToMachine');
      gameContext?.changeVideoTab('bottleLoadToMachine');

      return;
    }

    if (onClickNext) {
      onClickNext();
    } else {
      gameContext?.setShowFinishComponent(true);
    }
  };

  return (
    <>
      {!withoutQuote && (
        <CSSTransition
          in={isVisible && !isVisibleFinish}
          timeout={200}
          classNames="fadeSimple"
        >
          <div
            className={cn(
              'fadeSimple',
              styles.overlay,
              styles.overlay_bg,
              styles['overlay_' + currentStepName],
            )}
          >
            <div className={styles.wrapper}>
              <div className={styles.photo}>
                <img src={photoImage} alt="" />
              </div>
              <div className={styles.box}>
                <p className={cn(styles.name, 'subtitle')}>
                  Дмитрий Иванович Менделеев:
                </p>
                <div className={styles.content}>
                  {quoteText && <p className={styles.text}>{quoteText}</p>}
                  <button
                    className={styles.button}
                    type="button"
                    onClick={handleClickNext}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
      {currentFinishComponent && isVisibleFinish && (
        <div className={styles.overlay}>{currentFinishComponent()}</div>
      )}
    </>
  );
};
