import { useState, useEffect, useRef } from 'react';
import scrollSnapPolyfill from 'css-scroll-snap-polyfill';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import styles from './Home.module.scss';

import { Background } from '@/src/components/Background';
import { VoteLink } from '@/src/components/VoteLink';
import { Overlay } from '@/src/components/Overlay';
import { Mendeleev } from '@/src/components/Mendeleev';
import { Worth } from './components/Worth';
import { Footer } from '@/src/components/Footer';
import btn_scroll from './images/btn_scroll.svg';
import rules_icon_1 from './images/rules_icon_1.svg';
import rules_icon_2 from './images/rules_icon_2.svg';
import cycle_icon_1 from './images/cycle_icon_1.png';
import cycle_icon_2 from './images/cycle_icon_2.png';
import cycle_icon_3 from './images/cycle_icon_3.png';
import cycle_icon_4 from './images/cycle_icon_4.png';
import cycle_icon_5 from './images/cycle_icon_5.png';
import { CycleSlider } from './components/CycleSlider';
import sendEventToCounters from '@/src/counterEvents';

export const cycleText: string[] = [
  'Ты сдаёшь пластиковые бутылки в\u00A0пункты приёма вторсырья',
  'Они попадают в\u00A0сортировочные центры, где их очищают от\u00A0крышек и\u00A0этикеток, разбирают по\u00A0цветам и\u00A0измельчают',
  'Дроблёные бутылки (или\u00A0флекса) тщательно моются и\u00A0попадают на\u00A0предприятие СИБУРа, где из\u00A0них создают сырьё для\u00A0новых экологичных бутылок',
  'Напитки в\u00A0новых бутылках снова попадают на\u00A0полки магазинов',
  'После использования эти\u00A0бутылки можно опять сдать в\u00A0переработку, сделав <b>цикл почти бесконечным</b>!',
];

type TProps = {
  changePage: (s: string) => void;
};

export const Home = ({ changePage }: TProps) => {
  const [isPopup, setIsPopup] = useState(false);
  const [isFirstScreenInView, setIsFirstScreenInView] = useState(true);
  const screen1Ref = useRef<HTMLDivElement | null>(null);
  const screen2Ref = useRef<HTMLDivElement | null>(null);

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'landing-page', label });
  };

  const handleClickGoGame = (label: string) => {
    changePage('progress');
    sendAnalytics(label);
  };

  useEffect(() => {
    scrollSnapPolyfill();
  }, []);

  useEffect(() => {
    if (isPopup) {
      document.body.style.overflow = 'hidden';

      if (document.body.clientWidth > 1019) {
        document.body.style.paddingRight = '15px';
        document.body.classList.add('lock');
      }
    }
    return () => {
      document.body.style.overflow = '';

      if (document.body.clientWidth > 1019) {
        document.body.style.paddingRight = '';
        document.body.classList.remove('lock');
      }
    };
  }, [isPopup]);

  useEffect(() => {
    const el = screen1Ref.current as HTMLDivElement;

    const options = {
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsFirstScreenInView(entry.isIntersecting);
    }, options);

    observer.observe(el);
  }, []);

  const handleScrollBtnClick = () => {
    screen2Ref?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <>
      <div className={cn(styles.home)}>
        <Background />
        <div
          className={cn(
            styles.mendeleev_wrap,
            isFirstScreenInView ? '' : styles.is_visible,
          )}
        >
          <div className={styles.mendeleev}>
            <Mendeleev isHand={isPopup} />
          </div>
        </div>
        <div className={cn(styles.content_container, 'container')}>
          <div className={cn(styles.screen, styles.screen_1)} ref={screen1Ref}>
            <div className={cn(styles.screen_1_content)}>
              <div className={styles.main_title_wrap}>
                <p className={cn('subtitle')}>Дмитрий Иванович Менделеев:</p>
                <h1 className={styles.main_title}>
                  Забота о&#160;планете&#160;– цель, достойная тебя?
                </h1>
              </div>
              <p className={styles.caption}>
                Посети исследовательский центр СИБУР ПолиЛаб виртуально
                и&#160;создай инновационную упаковку с&#160;добавлением
                переработанного пластика!
              </p>
              <div className={cn(styles.cta, 'flex')}>
                <p>
                  Амбициозная задача?
                  <i> </i>
                  <span>Жми, чтобы начать игру</span>
                </p>
                <button
                  className={cn(styles.cta_btn, 'btn')}
                  onClick={() => handleClickGoGame('get-started')}
                >
                  Приступаю!
                </button>
              </div>
              <div className={cn(styles.support, 'flex')}>
                <p>Поддержи СИБУР в&#160;Рейтинге работодателей России</p>
                {/* <VoteLink text="Голосовать" classNames={['vote_btn']} /> */}
                <VoteLink text="Голосовать" />
              </div>
            </div>
            <button
              className={styles.btn_scroll}
              onClick={(): void => handleScrollBtnClick()}
            >
              <p className={styles.btn_scroll_text_l}>
                ЛИСТАЙТЕ
                <span className={styles.horizontal}> ВНИЗ</span>
                <span className={styles.vertical}> ВЛЕВО</span>
              </p>
              <p className={styles.btn_scroll_text_xs}>Узнать больше</p>
              <img src={btn_scroll} width={90} height={98} alt="" />
            </button>
          </div>
          <div className={cn(styles.screen, styles.screen_2)} ref={screen2Ref}>
            <h2 className={cn('title')}>Что тебя ждет:</h2>
            <div className={cn(styles.rules, 'flex')}>
              <div className={cn(styles.rules_item, styles.rules_item_1)}>
                <img
                  className={styles.rules_item_icon}
                  src={rules_icon_1}
                  width={99}
                  height={99}
                  alt="Иконка"
                />
                <p>
                  Видео-экскурсия по&#160;исследовательс&#173;кому центру СИБУР
                  ПолиЛаб
                </p>
              </div>
              <div className={cn(styles.rules_item, styles.rules_item_2)}>
                <img
                  className={styles.rules_item_icon}
                  src={rules_icon_2}
                  width={99}
                  height={99}
                  alt="Иконка"
                />
                <p>
                  Возможность
                  <br />
                  управлять сюжетом
                  <br />
                  по&#160;клику
                </p>
              </div>
            </div>
            <button
              className={cn(styles.interesting_btn)}
              onClick={(): void => setIsPopup(true)}
            >
              <span>Интересный факт</span>
            </button>
          </div>
          <div
            className={cn(
              styles.screen,
              styles.screen_3,
              styles.screen_3_vertical,
            )}
          >
            <h2 className={cn(styles.cycle_title, 'title')}>
              Вместе с&#160;СИБУРом забота о&#160;планете доступна каждому:
            </h2>
            <div className={cn(styles.cycle)}>
              <div className={cn(styles.cycle_infographics)}>
                <img
                  className={cn(styles.cycle_icon, styles.cycle_icon_1)}
                  src={cycle_icon_1}
                  width={40}
                  height={40}
                  alt="Иконка"
                />
                <img
                  className={cn(styles.cycle_icon, styles.cycle_icon_2)}
                  src={cycle_icon_2}
                  width={40}
                  height={40}
                  alt="Иконка"
                />
                <img
                  className={cn(styles.cycle_icon, styles.cycle_icon_3)}
                  src={cycle_icon_3}
                  width={40}
                  height={40}
                  alt="Иконка"
                />
                <img
                  className={cn(styles.cycle_icon, styles.cycle_icon_4)}
                  src={cycle_icon_4}
                  width={40}
                  height={40}
                  alt="Иконка"
                />
                <img
                  className={cn(styles.cycle_icon, styles.cycle_icon_5)}
                  src={cycle_icon_5}
                  width={40}
                  height={40}
                  alt="Иконка"
                />
                {cycleText.map((text, i) => {
                  return (
                    <div
                      className={cn(
                        styles.cycle_item,
                        styles[`cycle_item_${i + 1}`],
                      )}
                      key={i}
                    >
                      <p dangerouslySetInnerHTML={{ __html: text }}></p>
                    </div>
                  );
                })}
              </div>
              <div className={styles.cycle_slider}>
                <CycleSlider />
              </div>
            </div>
            <div className={styles.worth}>
              <Worth />
            </div>
          </div>
          <div className={cn(styles.screen, styles.screen_3_vote)}>
            <Worth />
          </div>
          <div
            className={cn(
              styles.screen,
              styles.screen_4,
              styles.screen_with_footer,
            )}
          >
            <div className={styles.ready}>
              <p className={cn('subtitle')}>Дмитрий Иванович Менделеев:</p>
              <p className={cn(styles.speech)}>
                Готов протестировать мощности СИБУР ПолиЛаб?
              </p>
            </div>
            <button
              className={cn(styles.bottom_btn, 'btn')}
              onClick={() => handleClickGoGame('start')}
            >
              Начать
            </button>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      <>
        <Overlay isActive={isPopup} onClick={(): void => setIsPopup(false)} />
        <CSSTransition in={isPopup} timeout={400} classNames="fadeInUp">
          <div className={cn(styles.popup, 'fadeInUp')}>
            <button
              className={styles.popup_close}
              onClick={(): void => setIsPopup(false)}
            ></button>
            <p className={cn(styles.popup_subtitle, 'subtitle')}>
              Дмитрий Иванович Менделеев:
            </p>
            <p className={styles.popup_text}>
              В&#160;2022 году СИБУР выпустил инновационный материал
              с&#160;добавлением вторичного пластика. Теперь более миллиарда
              использованных пластиковых бутылок не&#160;пойдут на&#160;мусорные
              полигоны, а&#160;будут возвращены обратно в&#160;производство!
            </p>
          </div>
        </CSSTransition>
      </>
    </>
  );
};
