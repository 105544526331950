import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import { cycleText } from '../../Home';
import cycle_icon_1 from '../../images/cycle_icon_1.png';
import cycle_icon_2 from '../../images/cycle_icon_2.png';
import cycle_icon_3 from '../../images/cycle_icon_3.png';
import cycle_icon_4 from '../../images/cycle_icon_4.png';
import cycle_icon_5 from '../../images/cycle_icon_5.png';
import styles from './CycleSlider.module.scss';

const icons: string[] = [
  cycle_icon_1,
  cycle_icon_2,
  cycle_icon_3,
  cycle_icon_4,
  cycle_icon_5,
];

// type TProps = {};

export const CycleSlider = () => {
  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={20}
      slidesPerView={1}
      pagination={{ clickable: true }}
    >
      {cycleText.map((text, i) => {
        return (
          <SwiperSlide key={i}>
            <div className={styles.slide}>
              <div className={styles.slide_icon}>
                <img src={icons[i]} width={40} height={40} alt="Иконка" />
              </div>
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
