/* eslint-disable @typescript-eslint/no-unused-vars */

import cn from 'classnames';
import styles from './Progress.module.scss';
import { Logo } from '@/src/components/Logo';
import { Mendeleev } from '@/src/components/Mendeleev';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Rotate } from '../Rotate';

const LIMIT_PERCENT = 100;

type TProps = {
  changePage: (pageName: string) => void;
};

export const Progress = ({ changePage }: TProps) => {
  const isTablet = useMediaQuery('(max-width: 1023px)');
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [isNeedRollDevice, setIsNeedRollDevice] = useState(false);

  const [currentPercent, setCurrentPercent] = useState(0);
  let intervalId: ReturnType<typeof setInterval>;

  useEffect(() => {
    const interval = () => {
      if (isNeedRollDevice) {
        clearInterval(intervalId);
        return;
      }

      if (currentPercent >= LIMIT_PERCENT) {
        clearInterval(intervalId);
        changePage('game');
        return;
      }

      setCurrentPercent((prev) => prev + 1);
    };

    intervalId = setInterval(
      interval,
      process.env.NODE_ENV === 'development' ? 1 : 50,
    );

    return () => clearInterval(intervalId);
  }, [currentPercent, isNeedRollDevice]);

  useEffect(() => {
    setIsNeedRollDevice(isTablet && isPortrait);
  }, [isTablet, isPortrait]);

  useEffect(() => {
    if (isNeedRollDevice) {
      setCurrentPercent(0);
    }
  }, [isNeedRollDevice]);

  return isNeedRollDevice ? (
    <Rotate />
  ) : (
    <div className={cn(styles.progress, 'bg')}>
      <div className="mendeleev_wrap">
        <div className={styles.mendeleev}>
          <Mendeleev />
        </div>
      </div>
      <div className={cn(styles.content_container, 'container')}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={cn(styles.screen)}>
          <div className={styles.percent}>
            <p>
              <span>{currentPercent}</span>%
            </p>
          </div>
          <div className={styles.bar}>
            <div
              className={styles.bar_progress}
              style={{ width: `${currentPercent}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
