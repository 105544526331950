export default function sendEventToCounters(params) {
  const { action, label } = params;

  // eslint-disable-next-line no-console
  // console.log('GOAL: ', `sibur_${action}_${label}`, params);

  if (typeof ym !== 'undefined') {
    // eslint-disable-next-line no-undef
    ym(94756056, 'reachGoal', `sibur_${action}_${label}`);
  }
}
