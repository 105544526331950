import cn from 'classnames';

import styles from './Background.module.scss';
import { Logo } from '@/src/components/Logo';
import bg from '@/src/images/bg.jpg';

// type TProps = {};

export const Background = () => {
  return (
    <div className={cn(styles.bg, 'bg')}>
      <img
        className={styles.bg_img}
        src={bg}
        width={1920}
        height={829}
        alt="Фон"
      />
      <div className={cn(styles.container, 'container')}>
        <div className={styles.logo}>
          <Logo />
        </div>
      </div>
    </div>
  );
};
