import cn from 'classnames';
import styles from './WorkplacePreparation.module.scss';
import { useGameContext } from '@/src/pages/Game';
import flexa from './images/flexa.png';
import bottle from './images/bottle.png';
import pet from './images/pet.png';
import sendEventToCounters from '@/src/counterEvents';

export const WorkplacePreparation = () => {
  const gameContext = useGameContext();

  const handleChoice = (stepName: string, material = '') => {
    gameContext?.setCurrentStepName(stepName);
    gameContext?.changeVideoTab(stepName);

    gameContext?.setBottleMaterial(material);
  };

  return (
    <div className={cn('choice', styles.wrapper)}>
      <div
        className={cn(styles.element, styles.element_1, 'choice_element')}
        onClick={() => {
          handleChoice('processing', 'flex');
          sendEventToCounters({ action: 'video2', label: 'flexa' });
        }}
      >
        <img src={flexa} alt="" />
      </div>
      <div
        className={cn(styles.element, styles.element_2, 'choice_element')}
        onClick={() => {
          handleChoice('workplacePreparationWrong');
          sendEventToCounters({ action: 'video2', label: 'experience' });
        }}
      >
        <img src={bottle} alt="" />
      </div>
      <div
        className={cn(styles.element, styles.element_3, 'choice_element')}
        onClick={() => {
          handleChoice('processing', 'plastic');
          sendEventToCounters({ action: 'video2', label: 'pet' });
        }}
      >
        <img src={pet} alt="" />
      </div>
    </div>
  );
};
