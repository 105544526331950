import { GAME_STEPS } from '@/src/pages/Game/data';
import { VideoContent } from './VideoContent';
import cn from 'classnames';

type TProps = {
  currentStepName: string;
  innerRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const Video = ({ currentStepName, innerRef }: TProps) => {
  return (
    <div ref={innerRef}>
      {Object.entries(GAME_STEPS).map(([key, value]) => {
        const { video } = value;
        const isVisible = currentStepName === key;

        return (
          <div
            className={cn('video_tab', {
              ['video_tab--active']: isVisible,
            })}
            data-id={key}
            key={`${key}_${key}`}
          >
            <VideoContent video={video} currentStepName={currentStepName} />
          </div>
        );
      })}
    </div>
  );
};
