import { useMemo, useState } from 'react';
import { Game } from '../../pages/Game';
import { Home } from '../../pages/Home';
import './App.scss';
import { Progress } from '../Progress';
import { GAME_STEPS } from '@/src/pages/Game/data';

export const App = () => {
  const [page, setPage] = useState('home'); // home / game

  const changePage = (pageName: string) => {
    setPage(pageName);
  };

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home changePage={changePage} />;
      case 'progress':
        return <Progress changePage={changePage} />;
      case 'game':
        return <Game changePage={changePage} />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  return (
    <>
      {renderCurrentPage}
      {(page === 'home' || page === 'progress') && (
        <div className="hidden">
          <video preload="auto" muted autoPlay playsInline>
            <source
              src={GAME_STEPS.clothesChoice.video.webm}
              type='video/webm;codecs="vp8, vorbis"'
            />
            <source
              src={GAME_STEPS.clothesChoice.video.mp4}
              type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </div>
      )}
    </>
  );
};
