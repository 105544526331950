import { useState } from 'react';
import cn from 'classnames';
import styles from './BottleLoadToMachine.module.scss';
import { useGameContext } from '@/src/pages/Game';
import sendEventToCounters from '@/src/counterEvents';

export const BottleLoadToMachine = () => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const gameContext = useGameContext();

  const handleChoice = (stepName: string) => {
    gameContext?.setCurrentStepName(stepName);
    gameContext?.changeVideoTab(stepName);
  };

  return (
    <div className={styles.elements}>
      <div className={cn(styles.container, 'container')}>
        <p className={styles.title}>
          Выбери уровень давления для&#160;проверки прочности образца:
        </p>
        <div
          className={cn(isHover ? styles.hover : '', styles.wrapper, 'flex')}
        >
          <input
            className={styles.input}
            type="radio"
            name="pressure"
            id="pressure1"
          />
          <label
            className={cn(styles.label, styles.element, styles.element_3)}
            htmlFor="pressure1"
            onClick={() => {
              handleChoice(
                gameContext?.bottleMaterial === 'flex'
                  ? 'bottleTestingUnSuccessFlexa'
                  : 'bottleTestingUnSuccessPet',
              );
              sendEventToCounters({
                action: 'video-passes',
                label: 'finish-watching',
              });
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            как на орбите
          </label>
          <input
            className={styles.input}
            type="radio"
            name="pressure"
            id="pressure2"
          />
          <label
            className={cn(styles.label, styles.element, styles.element_2)}
            htmlFor="pressure2"
            onClick={() => {
              handleChoice(
                gameContext?.bottleMaterial === 'flex'
                  ? 'bottleTestingSuccess'
                  : 'bottleTestingAlmostSuccess',
              );
              sendEventToCounters({
                action: 'video-result',
                label:
                  gameContext?.bottleMaterial === 'flex'
                    ? 'bottle-flexa'
                    : 'bottle-pet',
              });
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            как при взлете самолета
          </label>
          <input
            className={styles.input}
            type="radio"
            name="pressure"
            id="pressure3"
          />
          <label
            className={cn(styles.label, styles.element, styles.element_1)}
            htmlFor="pressure3"
            onClick={() => {
              handleChoice(
                gameContext?.bottleMaterial === 'flex'
                  ? 'bottleTestingSuccess'
                  : 'bottleTestingAlmostSuccess',
              );
              sendEventToCounters({
                action: 'video-result',
                label:
                  gameContext?.bottleMaterial === 'flex'
                    ? 'bottle-flexa'
                    : 'bottle-pet',
              });
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            как у дверей лифта
          </label>
          <div className={styles.bar}>
            <div className={styles.bar_level}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
