import cn from 'classnames';
import styles from './ViolationProcessing.module.scss';
import { useGameContext } from '@/src/pages/Game';
import { QuoteMendeleev } from '../QuoteMendeleev';
import { useState } from 'react';
// import violationBg from './images/violation.jpg';
import worker from './images/worker.png';
import hatch from './images/hatch.png';
import loader from './images/loader.png';
import sendEventToCounters from '@/src/counterEvents';

export const ViolationProcessing = () => {
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const gameContext = useGameContext();

  const handleChoice = (stepName: string) => {
    gameContext?.setCurrentStepName(stepName);
    gameContext?.changeVideoTab(stepName);
  };

  const handleClickCorrectElement = () => {
    sendEventToCounters({ action: 'video5', label: 'fixed-right' });

    setTimeout(() => {
      setIsVisibleQuote(true);
    }, 100);
  };

  return (
    <>
      {!isVisibleQuote && (
        <div className={cn('choice', styles.wrapper)}>
          {/* <img src={violationBg} alt="" className="choice_bg" /> */}

          <div
            className={cn(styles.element, styles.element_1, 'choice_element')}
            onClick={() => {
              handleChoice(
                gameContext?.correctOrder
                  ? 'violationWrongProcessingCorrect'
                  : 'violationWrongProcessingWrong',
              );
              sendEventToCounters({ action: 'video5', label: 'fixed-wrong' });
            }}
          >
            <img src={worker} alt="" />
          </div>
          <div
            className={cn(styles.element, styles.element_2, 'choice_element')}
            onClick={handleClickCorrectElement}
          >
            <img src={hatch} alt="" />
          </div>
          <div
            className={cn(styles.element, styles.element_3, 'choice_element')}
            onClick={() => {
              handleChoice(
                gameContext?.correctOrder
                  ? 'violationWrongProcessingCorrect'
                  : 'violationWrongProcessingWrong',
              );
              sendEventToCounters({ action: 'video5', label: 'fixed-wrong' });
            }}
          >
            <img src={loader} alt="" />
          </div>
        </div>
      )}

      <QuoteMendeleev
        currentStepName={'violationProcessing'}
        quoteText="Хорошая работа – нарушение устранено! Вот и настал этот волнующий момент: пора выдувать бутылку!"
        isVisible={isVisibleQuote}
        onClickNext={() => {
          handleChoice(
            gameContext?.correctOrder ? 'bottleCorrect' : 'bottleWrong',
          );
        }}
      />
    </>
  );
};
