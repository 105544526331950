import cn from 'classnames';
import styles from './ProcessingCorrect.module.scss';
import { useGameContext } from '@/src/pages/Game';
// import processingCorrectBg from './images/processingCorrect.jpg';
import form_1 from './images/form_1.png';
import form_2 from './images/form_2.png';
import form_3 from './images/form_3.png';
import sendEventToCounters from '@/src/counterEvents';

export const ProcessingCorrect = () => {
  const gameContext = useGameContext();

  const handleChoice = () => {
    gameContext?.setCurrentStepName('violationProcessing');
    gameContext?.changeVideoTab('violationProcessing');

    sendEventToCounters({ action: 'video4', label: 'anything-choice' });
  };

  return (
    <div className={cn('choice', styles.wrapper)}>
      {/* <img src={processingCorrectBg} alt="" className="choice_bg" /> */}
      <div
        className={cn(styles.element, styles.element_1, 'choice_element')}
        onClick={handleChoice}
      >
        <img src={form_1} alt="" />
      </div>
      <div
        className={cn(styles.element, styles.element_2, 'choice_element')}
        onClick={handleChoice}
      >
        <img src={form_2} alt="" />
      </div>
      <div
        className={cn(styles.element, styles.element_3, 'choice_element')}
        onClick={handleChoice}
      >
        <img src={form_3} alt="" />
      </div>
    </div>
  );
};
