import styles from './Logo.module.scss';
import logo_white from './images/logo_white.svg';

// type TProps = {};

export const Logo = () => {
  return (
    <img
      className={styles.logo}
      src={logo_white}
      width={184}
      height={33}
      alt="Логотип Сибур"
    />
  );
};
