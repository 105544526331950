import cn from 'classnames';
import styles from './ClothesChoice.module.scss';
import { useGameContext } from '@/src/pages/Game';
import robeImage from './images/robe.png';
import overallsImage from './images//overalls.png';
import sendEventToCounters from '@/src/counterEvents';

export const ClothesChoice = () => {
  const gameContext = useGameContext();

  const handleChoice = (stepName: string) => {
    gameContext?.setCurrentStepName(stepName);
    gameContext?.changeVideoTab(stepName);
  };

  return (
    <div className={cn('choice', styles.wrapper)}>
      <div
        className={cn('choice_element', styles.element, styles.element_robe)}
        onClick={() => {
          handleChoice('clothesChoiceWrong');
          sendEventToCounters({ action: 'video1', label: 'funny-robe' });
        }}
      >
        <img src={robeImage} alt="" />
      </div>
      <div
        className={cn(
          'choice_element',
          styles.element,
          styles.element_overalls,
        )}
        onClick={() => {
          handleChoice('workplacePreparation');
          sendEventToCounters({ action: 'video1', label: 'correct-form' });
        }}
      >
        <img src={overallsImage} alt="" />
      </div>
    </div>
  );
};
