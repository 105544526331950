import styles from './Mendeleev.module.scss';
import mendeleev from './images/mendeleev.png';
import mendeleev_hand from './images/mendeleev_hand.png';

type TProps = {
  isHand?: boolean;
};

export const Mendeleev = ({ isHand }: TProps) => {
  return (
    <>
      {/* preload images ? */}
      <img src={mendeleev_hand} alt="Менделеев" style={{ display: 'none' }} />
      <img
        className={styles.mendeleev}
        src={isHand ? mendeleev_hand : mendeleev}
        width={184}
        height={33}
        alt="Менделеев"
      />
    </>
  );
};
