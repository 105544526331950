import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import styles from './Overlay.module.scss';

type TProps = {
  isActive: boolean;
  onClick?: () => void;
};

export const Overlay = ({ isActive, onClick }: TProps) => {
  return (
    <>
      <CSSTransition in={isActive} timeout={200} classNames="fadeIn">
        <div className={cn(styles.overlay, 'fadeIn')} onClick={onClick}></div>
      </CSSTransition>
    </>
  );
};
