/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  createElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './Game.module.scss';
import { GAME_STEPS } from './data';
import { TContext, TStep, TVideo } from './types';
import { Video } from '@/src/components/Video';
import useWindowSize from '@/src/hooks/useWindowSize';
import expectationAudio from './expectation.mp3';
import { QuoteMendeleev } from '@/src/components/QuoteMendeleev';
import { useMediaQuery } from 'usehooks-ts';
import { Rotate } from '@/src/components/Rotate';
import { isMobile } from 'react-device-detect';

type TProps = {
  changePage: (s: string) => void;
};

const GameContext = createContext<TContext | null>(null);

export const useGameContext = () => useContext(GameContext);

const openFullScreen = () => {
  // Trigger fullscreen
  const docElmWithBrowsersFullScreenFunctions =
    document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

  if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
    docElmWithBrowsersFullScreenFunctions.requestFullscreen();
  } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
    /* Firefox */
    docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
  } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
  } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
    /* IE/Edge */
    docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
  }
};

export const Game = ({ changePage }: TProps) => {
  const [currentStepName, setCurrentStepName] = useState('clothesChoice');
  // интерактивные:
  // clothesChoice,
  // workplacePreparation,
  // processing,
  // processingCorrect, processingWrong,
  // violationProcessingCorrect, violationProcessingWrong,
  // bottleLoadToMachine

  const isTablet = useMediaQuery('(max-width: 1023px)');
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [isNeedRollDevice, setIsNeedRollDevice] = useState(false);

  useEffect(() => {
    setIsNeedRollDevice(isTablet && isPortrait);
  }, [isTablet, isPortrait]);

  const [showFinishComponent, setShowFinishComponent] = useState(false);
  const [showQuoteMendeleev, setShowQuoteMendeleev] = useState(false);
  const [isShowButtonSound, setIsShowButtonSound] = useState(true);
  const [isInitialGame, setIsInitialGame] = useState(false);
  const [bottleMaterial, setBottleMaterial] = useState('');
  const [correctOrder, setCorrectOrder] = useState(false);

  const { width, height } = useWindowSize();

  const videoTabsRef = useRef<HTMLDivElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const currentStep = useMemo(
    () => GAME_STEPS[currentStepName as keyof typeof GAME_STEPS],
    [currentStepName],
  );

  const currentFinishComponent = useMemo(
    () => currentStep.finishComponent,
    [currentStep],
  );

  const isFinalStep = useMemo(() => currentStep.isFinal, [currentStep]);

  const quoteText = useMemo(() => currentStep.quoteText, [currentStep]);

  const withoutQuote = useMemo(() => currentStep.withoutQuote, [currentStep]);

  useEffect(() => {
    setShowQuoteMendeleev(false);
    setShowFinishComponent(false);
  }, [currentStepName]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      if (showQuoteMendeleev) {
        if (!isFinalStep) {
          audio.play();
        }
      } else {
        const isPlaying =
          audio.currentTime > 0 &&
          !audio.paused &&
          !audio.ended &&
          audio.readyState > audio.HAVE_CURRENT_DATA;
        if (isPlaying) {
          audio.pause();
          audio.currentTime = 0;
        }
      }
    }
  }, [showQuoteMendeleev]);

  const handleForceStartVideo = () => {
    // openFullScreen();

    const video = videoTabsRef.current?.querySelector<HTMLVideoElement>(
      `div[data-id="${currentStepName}"] video`,
    );
    video?.play();
    setIsShowButtonSound(false);
    if (!isInitialGame) {
      setIsInitialGame(true);
    }
  };

  const changeVideoTab = (nextVideo: string) => {
    const videoTabs = videoTabsRef.current?.querySelectorAll('div');

    videoTabs?.forEach((tab) => {
      const video = tab.querySelector('video');
      if (tab.dataset.id === nextVideo) {
        tab.classList.add('video_tab--active');

        if (video) {
          video.play();
        }
      } else {
        tab.classList.remove('video_tab--active');

        if (video) {
          video.currentTime = 0;
        }
      }
    });
  };

  useEffect(() => {
    const video: HTMLVideoElement | null | undefined =
      videoTabsRef.current?.querySelector(
        `.video_tab[data-id="${currentStepName}"] video`,
      );
    const audio = audioRef.current;

    if (isNeedRollDevice) {
      video?.pause();

      if (
        !showFinishComponent &&
        !showQuoteMendeleev &&
        !(showFinishComponent || (showQuoteMendeleev && Boolean(isFinalStep)))
      ) {
        setIsShowButtonSound(true);
      }

      if (audio) {
        const isPlaying =
          audio.currentTime > 0 &&
          !audio.paused &&
          !audio.ended &&
          audio.readyState > audio.HAVE_CURRENT_DATA;
        if (isPlaying) {
          audio.pause();
          audio.currentTime = 0;
        }
      }
    } else {
      if (showQuoteMendeleev && audio) {
        if (!isFinalStep) {
          audio.play();
        }
      }
    }
    // else if (video && isMobile && isInitialGame) {
    //   video.style.width = `${width}px`;
    //   video.style.height = `${height + 4}px`;
    // }
  }, [isNeedRollDevice]);

  return (
    <GameContext.Provider
      value={{
        setCurrentStepName,
        setShowFinishComponent,
        setShowQuoteMendeleev,
        changeVideoTab,
        bottleMaterial,
        setBottleMaterial,
        correctOrder,
        setCorrectOrder,
      }}
    >
      <div className={styles.game}>
        <Video innerRef={videoTabsRef} currentStepName={currentStepName} />

        <QuoteMendeleev
          currentStepName={currentStepName}
          quoteText={quoteText}
          isVisible={showQuoteMendeleev && !isFinalStep}
          isVisibleFinish={
            showFinishComponent || (showQuoteMendeleev && Boolean(isFinalStep))
          }
          currentFinishComponent={currentFinishComponent}
          withoutQuote={withoutQuote}
        />

        {isShowButtonSound && (
          <div className={styles.button_wrapper}>
            <button className="btn" onClick={handleForceStartVideo}>
              {isInitialGame ? 'Продолжить' : 'Включить звук'}
            </button>
          </div>
        )}
      </div>
      <audio ref={audioRef} src={expectationAudio} loop></audio>

      {isNeedRollDevice && <Rotate />}
    </GameContext.Provider>
  );
};
