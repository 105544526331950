import cn from 'classnames';

import hh_logo from './images/hh_logo.svg';
import styles from './Footer.module.scss';
import sendEventToCounters from '@/src/counterEvents';

// type TProps = {};

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={cn(styles.footer_container, 'flex')}>
        <div className={cn(styles.copyright, 'flex')}>
          <p className={styles.brand}>© СИБУР 2023</p>
          <div className={cn(styles.hh, 'flex')}>
            <img className={styles.hh_logo} src={hh_logo} alt="Логотип HH.ru" />
            <p className={styles.hh_text}>Бренд-центр</p>
          </div>
        </div>
        <ul className={cn(styles.links, 'flex')}>
          <li className={styles.link_item}>
            <a
              className={styles.link}
              href="https://hh.ru/admin/employer.do?employerId=3809"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                sendEventToCounters({ action: 'footer', label: 'vacancies' })
              }
            >
              Вакансии
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
