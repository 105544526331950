import styles from './Final.module.scss';
import { useGameContext } from '@/src/pages/Game';
import cn from 'classnames';

import { Background } from '@/src/components/Background';
import { Mendeleev } from '@/src/components/Mendeleev';
import { Footer } from '@/src/components/Footer';
import { VoteLink } from '../VoteLink';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  text: string;
  replayCaption?: string;
  voteText: string;
  buttonRetryText?: string;
  buttonVoteText: string;
  retryStepName?: string;
  hideRetryButton?: boolean;
};

export const Final = ({
  text,
  replayCaption,
  voteText,
  buttonRetryText,
  buttonVoteText,
  retryStepName,
  hideRetryButton = false,
}: TProps) => {
  const gameContext = useGameContext();

  const handleRetryGame = () => {
    if (retryStepName) {
      gameContext?.setCurrentStepName(retryStepName);
      gameContext?.changeVideoTab(retryStepName);

      gameContext?.setShowQuoteMendeleev(false);
      gameContext?.setShowFinishComponent(false);
    }
  };

  const btnRetryText = buttonRetryText
    ? buttonRetryText
    : 'Вернуться в\u00A0игру';

  const variant = replayCaption ? 'two' : 'one';

  return (
    <div className={cn(styles.final)}>
      <div className={cn(styles.final_wrapper)}>
        <Background />
        <div className={styles.mendeleev}>
          <Mendeleev />
        </div>
        <div className={cn(styles.content_container, 'container')}>
          <div className={styles.content}>
            <div className={styles.bubble}>
              <p className={cn('subtitle')}>Дмитрий Иванович Менделеев:</p>
              <p className={cn(styles.text, styles[variant])}>{text}</p>
            </div>
            {Boolean(variant === 'one') && (
              <div className={cn(styles.buttons, styles['one'], 'flex')}>
                <p className={styles.buttons_text_wide}>{voteText}</p>
                {!hideRetryButton && (
                  <button
                    className={cn(styles.return_to_game)}
                    onClick={handleRetryGame}
                  >
                    {btnRetryText}
                  </button>
                )}
                <VoteLink
                  text={buttonVoteText}
                  classNames={[styles.vote]}
                  onClick={() => {
                    sendEventToCounters({
                      action: 'results',
                      label: 'support-sibur',
                    });
                  }}
                />
              </div>
            )}
            {Boolean(variant === 'two') && (
              <div className={cn(styles.buttons, styles['two'], 'flex')}>
                <div className={styles.buttons_left}>
                  <p className={styles.buttons_text}>{replayCaption}</p>
                  {!hideRetryButton && (
                    <button
                      className={cn(styles.return_to_game)}
                      onClick={() => {
                        handleRetryGame();
                      }}
                    >
                      {btnRetryText}
                    </button>
                  )}
                </div>
                <div className={styles.buttons_right}>
                  <p className={styles.buttons_text}>{voteText}</p>
                  <VoteLink
                    text={buttonVoteText}
                    classNames={[styles.vote]}
                    onClick={() => {
                      sendEventToCounters({
                        action: 'results',
                        label: 'support-sibur',
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
