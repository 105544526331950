import { Final } from '../../components/Final/Final';
import { ClothesChoice } from '../../components/ClothesChoice';
import { WorkplacePreparation } from '@/src/components/WorkplacePreparation';
import { Processing } from '@/src/components/Processing';
import { ProcessingWrong } from '@/src/components/ProcessingWrong';
import { ProcessingCorrect } from '@/src/components/ProcessingCorrect';
import { ViolationProcessing } from '@/src/components/ViolationProcessing';
import { BottleCorrect } from '@/src/components/BottleCorrect';
import { BottleLoadToMachine } from '@/src/components/BottleLoadToMachine';
import { TStep } from './types';

import clothesChoiceVideoMP4 from './videos/clothesChoice.mp4';
import clothesChoiceVideoWEBM from './videos/clothesChoice.webm';
import clothesChoiceVideoPoster from './videos/clothesChoice_poster.jpg';

import clothesChoiceWrongVideoMP4 from './videos/clothesChoiceWrong.mp4';
import clothesChoiceWrongVideoWEBM from './videos/clothesChoiceWrong.webm';
import clothesChoiceWrongVideoPoster from './videos/clothesChoiceWrong_poster.jpg';

import workplacePreparationMP4 from './videos/workplacePreparation.mp4';
import workplacePreparationWEBM from './videos/workplacePreparation.webm';
import workplacePreparationPoster from './videos/workplacePreparation_poster.jpg';

import workplacePreparationWrongMP4 from './videos/workplacePreparationWrong.mp4';
import workplacePreparationWrongWEBM from './videos/workplacePreparationWrong.webm';
import workplacePreparationWrongPoster from './videos/workplacePreparationWrong_poster.jpg';

import processingMP4 from './videos/processing.mp4';
import processingWEBM from './videos/processing.webm';
import processingPoster from './videos/processing_poster.jpg';

import processingWrongMP4 from './videos/processingWrong.mp4';
import processingWrongWEBM from './videos/processingWrong.webm';
import processingWrongPoster from './videos/processingWrong_poster.jpg';

import processingCorrectMP4 from './videos/processingCorrect.mp4';
import processingCorrectWEBM from './videos/processingCorrect.webm';
import processingCorrectPoster from './videos/processingCorrect_poster.jpg';

import violationMP4 from './videos/violation.mp4';
import violationWEBM from './videos/violation.webm';
import violationPoster from './videos/violation_poster.jpg';

import violationWrongMP4 from './videos/violationWrong.mp4';
import violationWrongWEBM from './videos/violationWrong.webm';
import violationWrongPoster from './videos/violationWrong_poster.jpg';

import bottleWrongMP4 from './videos/bottleWrong.mp4';
import bottleWrongWEBM from './videos/bottleWrong.webm';
import bottleWrongPoster from './videos/bottleWrong_poster.jpg';

import bottleCorrectMP4 from './videos/bottleCorrect.mp4';
import bottleCorrectWEBM from './videos/bottleCorrect.webm';
import bottleCorrectPoster from './videos/bottleCorrect_poster.jpg';

import bottleLoadToMachineMP4 from './videos/bottleLoadToMachine.mp4';
import bottleLoadToMachineWEBM from './videos/bottleLoadToMachine.webm';
import bottleLoadToMachinePoster from './videos/bottleLoadToMachine_poster.jpg';

import bottleTestingSuccessMP4 from './videos/bottleTestingSuccess.mp4';
import bottleTestingSuccessWEBM from './videos/bottleTestingSuccess.webm';
import bottleTestingSuccessPoster from './videos/bottleTestingSuccess_poster.jpg';

import bottleTestingAlmostSuccessMP4 from './videos/bottleTestingAlmostSuccess.mp4';
import bottleTestingAlmostSuccessWEBM from './videos/bottleTestingAlmostSuccess.webm';
import bottleTestingAlmostSuccessPoster from './videos/bottleTestingAlmostSuccess_poster.jpg';

import bottleTestingUnSuccessMP4 from './videos/bottleTestingUnSuccess.mp4';
import bottleTestingUnSuccessWEBM from './videos/bottleTestingUnSuccess.webm';
import bottleTestingUnSuccessPoster from './videos/bottleTestingUnSuccess_poster.jpg';

export const GAME_STEPS: Record<string, TStep> = {
  clothesChoice: {
    video: {
      mp4: clothesChoiceVideoMP4,
      webm: clothesChoiceVideoWEBM,
      poster: clothesChoiceVideoPoster,
    },
    quoteText:
      'Приветствую в\u00A0лаборатории исследовательского центра СИБУР ПолиЛаб. Сегодня будет отличный день, но\u00A0для начала стоит переодеться!',
    finishComponent: () => <ClothesChoice />,
  },

  clothesChoiceWrong: {
    video: {
      mp4: clothesChoiceWrongVideoMP4,
      webm: clothesChoiceWrongVideoWEBM,
      poster: clothesChoiceWrongVideoPoster,
    },
    finishComponent: () => (
      <Final
        text="Друг мой, вопрос безопасности не&#160;терпит шуток и&#160;компромиссов! В&#160;таком наряде лучше отправиться спать: может, что-то дельное приснится!"
        replayCaption="Ты в&#160;самом начале пути&#160;– не&#160;сдавайся! Попробуешь сыграть еще&#160;раз?"
        voteText="Не&#160;забудь проголосовать за&#160;СИБУР в&#160;Рейтинге работодателей России!"
        buttonRetryText="Играть"
        buttonVoteText="Голосовать"
        retryStepName="clothesChoice"
      />
    ),
    isFinal: true,
  },

  workplacePreparation: {
    video: {
      mp4: workplacePreparationMP4,
      webm: workplacePreparationWEBM,
      poster: workplacePreparationPoster,
    },
    quoteText:
      'Ну что, пофлексим? Я\u00A0хотел сказать: пришло время выбрать сырье для производства пластиковой бутылки! Но\u00A0не\u00A0забудь, что наша бутылка должна быть создана из\u00A0переработанных материалов!',
    finishComponent: () => <WorkplacePreparation />,
  },

  workplacePreparationWrong: {
    video: {
      mp4: workplacePreparationWrongMP4,
      webm: workplacePreparationWrongWEBM,
      poster: workplacePreparationWrongPoster,
    },
    finishComponent: () => (
      <Final
        text="Друг мой, кажется у&#160;тебя недосып! Еще не&#160;изготовил бутылку, а&#160;уже проводишь испытания. Попробуй найти правильный материал: у&#160;тебя еще есть шанс на&#160;успех."
        voteText="Тестирование инновационного полимера&#160;– задача не из&#160;легких. Поддержи работу СИБУРа&#160;– голосуй за&#160;нас в&#160;Рейтинге работодателей России."
        buttonVoteText="Голосовать"
        retryStepName="workplacePreparation"
      />
    ),
    isFinal: true,
  },

  processing: {
    video: {
      mp4: processingMP4,
      webm: processingWEBM,
      poster: processingPoster,
    },
    quoteText:
      'Перед тобой ответственная задача\u00A0– установить правильную последовательность действий для\u00A0создания нового материала. Нажимай на\u00A0аппараты в\u00A0той последовательности, которую считаешь верной.',
    finishComponent: () => <Processing />,
  },

  processingWrong: {
    video: {
      mp4: processingWrongMP4,
      webm: processingWrongWEBM,
      poster: processingWrongPoster,
    },
    quoteText:
      'Вот и\u00A0готовые преформы. Теперь давай определим, какой образец достоин стать бутылкой!',
    finishComponent: () => <ProcessingWrong />,
  },

  processingCorrect: {
    video: {
      mp4: processingCorrectMP4,
      webm: processingCorrectWEBM,
      poster: processingCorrectPoster,
    },
    quoteText:
      'Вот и\u00A0готовые преформы. Теперь давай определим, какой образец достоин стать бутылкой!',
    finishComponent: () => <ProcessingCorrect />,
  },

  violationProcessing: {
    video: {
      mp4: violationMP4,
      webm: violationWEBM,
      poster: violationPoster,
    },
    quoteText:
      'Одну минуту, кажется, в\u00A0цеху допущено нарушение! Укажи на\u00A0него коллегам, чтобы они могли оперативно все исправить.',
    finishComponent: () => <ViolationProcessing />,
  },

  violationWrongProcessingWrong: {
    video: {
      mp4: violationWrongMP4,
      webm: violationWrongWEBM,
      poster: violationWrongPoster,
    },
    finishComponent: () => (
      <Final
        text="Ох, голубчик, ты и&#160;cам правила не&#160;помнишь. В&#160;таком случае, к&#160;производственным процессам тебя допускать нельзя, ведь наше главное правило&#160;– безопасность без&#160;компромиссов!"
        replayCaption="Жаль останавливать такой важный процесс в середине пути: попробуешь сыграть еще раз?"
        voteText="Готов менять мир вместе с&#160;СИБУРом? Поддержи нас в&#160;Рейтинге работодателей России!"
        buttonVoteText="Поддержать"
        retryStepName="violationProcessing"
      />
    ),
    isFinal: true,
  },

  violationWrongProcessingCorrect: {
    video: {
      mp4: violationWrongMP4,
      webm: violationWrongWEBM,
      poster: violationWrongPoster,
    },
    finishComponent: () => (
      <Final
        text="Ох, голубчик, ты и&#160;cам правила не&#160;помнишь. В&#160;таком случае, к&#160;производственным процессам тебя допускать нельзя, ведь наше главное правило&#160;– безопасность без&#160;компромиссов!"
        replayCaption="Жаль останавливать такой важный процесс в середине пути: попробуешь сыграть еще раз?"
        voteText="Готов менять мир вместе с&#160;СИБУРом? Поддержи нас в&#160;Рейтинге работодателей России!"
        buttonVoteText="Поддержать"
        retryStepName="violationProcessing"
      />
    ),
    isFinal: true,
  },

  violationCorrectProcessingWrong: {
    video: {
      mp4: violationWrongMP4,
      webm: violationWrongWEBM,
      poster: violationWrongPoster,
    },
    finishComponent: () => (
      <Final
        text="Ох, голубчик, ты и&#160;cам правила не&#160;помнишь. В&#160;таком случае, к&#160;производственным процессам тебя допускать нельзя, ведь наше главное правило&#160;– безопасность без&#160;компромиссов!"
        replayCaption="Жаль останавливать такой важный процесс в середине пути: попробуешь сыграть еще раз?"
        voteText="Готов менять мир вместе с&#160;СИБУРом? Поддержи нас в&#160;Рейтинге работодателей России!"
        buttonVoteText="Поддержать"
        retryStepName="violationProcessing"
      />
    ),
    isFinal: true,
  },

  bottleWrong: {
    video: {
      mp4: bottleWrongMP4,
      webm: bottleWrongWEBM,
      poster: bottleWrongPoster,
      analytic: {
        action: 'video-result',
        label: 'poor-quality',
      },
    },
    finishComponent: () => (
      <Final
        text="Друг мой, преформы не&#160;годятся к&#160;производству. Похоже, ты перепутал последовательность процессов. Попробуешь еще&#160;раз?"
        voteText="Тестирование инновационного полимера&#160;– непростая, но&#160;очень важная задача. Поддержи работу СИБУРа&#160;– голосуй за&#160;нас в&#160;Рейтинге работодателей России."
        buttonVoteText="Голосовать"
        retryStepName="processing"
      />
    ),
    isFinal: true,
  },

  bottleCorrect: {
    video: {
      mp4: bottleCorrectMP4,
      webm: bottleCorrectWEBM,
      poster: bottleCorrectPoster,
      analytic: {
        action: 'video-passes',
        label: 'good-bottle',
      },
    },
    quoteText:
      'Друг мой, поздравляю, отличная получилась бутылка! Теперь проведем испытания, чтобы проверить наш образец на\u00A0прочность.',
    finishComponent: () => <BottleCorrect />,
  },

  bottleLoadToMachine: {
    video: {
      mp4: bottleLoadToMachineMP4,
      webm: bottleLoadToMachineWEBM,
      poster: bottleLoadToMachinePoster,
    },
    withoutQuote: true,
    finishComponent: () => <BottleLoadToMachine />,
  },

  bottleTestingSuccess: {
    video: {
      mp4: bottleTestingSuccessMP4,
      webm: bottleTestingSuccessWEBM,
      poster: bottleTestingSuccessPoster,
    },
    finishComponent: () => (
      <Final
        text="Цель достигнута! У&#160;тебя получился качественный образец, и&#160;самое главное&#160;– из&#160;вторичного сырья. Отличная работа!"
        voteText="Ты покорил эту вершину и&#160;достойно справился с&#160;задачей. Помоги СИБУРу достигнуть цели&#160;– стать лучшим работодателем России!
        Поддержи нас в&#160;рейтинге"
        buttonVoteText="Голосовать"
        hideRetryButton
      />
    ),
    isFinal: true,
  },

  bottleTestingAlmostSuccess: {
    video: {
      mp4: bottleTestingAlmostSuccessMP4,
      webm: bottleTestingAlmostSuccessWEBM,
      poster: bottleTestingAlmostSuccessPoster,
    },
    finishComponent: () => (
      <Final
        text="Образец прошел испытания! Но&#160;ты забыл, что нужно было тестировать бутылку из&#160;переработанного пластика, а&#160;эта бутылка сделана из&#160;первичного ПЭТ.
        Не останавливайся на&#160;достигнутом и&#160;попробуй выбрать правильный материал для&#160;образца."
        voteText="Тестирование инновационного полимера&#160;– увлекательная и&#160;важная задача. Поддержи работу СИБУРа&#160;– голосуй за&#160;нас в&#160;Рейтинге работодателей России."
        buttonVoteText="Голосовать"
        retryStepName="workplacePreparation"
      />
    ),
    isFinal: true,
  },

  bottleTestingUnSuccessPet: {
    video: {
      mp4: bottleTestingUnSuccessMP4,
      webm: bottleTestingUnSuccessWEBM,
      poster: bottleTestingUnSuccessPoster,
    },
    finishComponent: () => (
      <Final
        text="Образец прошел испытания! Уровень предельного давления протестирован. Но&#160;ты забыл, что нужно было проверить бутылку из&#160;переработанного пластика, а&#160;эта бутылка сделана из&#160;первичного ПЭТ."
        replayCaption="Не&#160;останавливайся на достигнутом и&#160;попробуй выбрать правильный материал для&#160;образца."
        voteText="Тестирование инновационного полимера&#160;– увлекательная и&#160;важная задача. Поддержи работу СИБУРа&#160;– голосуй за&#160;нас в&#160;Рейтинге работодателей России."
        buttonVoteText="Голосовать"
        retryStepName="workplacePreparation"
      />
    ),
    isFinal: true,
  },

  bottleTestingUnSuccessFlexa: {
    video: {
      mp4: bottleTestingUnSuccessMP4,
      webm: bottleTestingUnSuccessWEBM,
      poster: bottleTestingUnSuccessPoster,
    },
    finishComponent: () => (
      <Final
        text="Цель достигнута! У&#160;тебя получился качественный образец с&#160;добавлением вторичного пластика. А&#160;предельное давление (как на&#160;орбите) даже качественная бутылка не&#160;выдержит."
        voteText="Ты&#160;покорил эту вершину и&#160;достойно справился с&#160;задачей. Помоги СИБУРу достигнуть цели&#160;&#8212; стать лучшим работодателем России! Поддержи нас в&#160;рейтинге"
        buttonVoteText="Голосовать"
        hideRetryButton
      />
    ),
    isFinal: true,
  },
};
