import { TVideo } from '@/src/pages/Game/types';
import { useState } from 'react';
import { Loader } from '../Loader';
import { isMobile } from 'react-device-detect';
import { useGameContext } from '@/src/pages/Game';
import sendEventToCounters from '@/src/counterEvents';
import { useUserAgent } from '@/src/hooks/useUserAgent';

type TProps = {
  video: TVideo;
  currentStepName: string;
};

export const VideoContent = ({ video, currentStepName }: TProps) => {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const { browser } = useUserAgent();
  const isBrowserEdge = browser.name === 'Edge';
  const isBrowserSafari = browser.name === 'Safari';

  const handleWaiting = () => {
    setIsShowLoading(true);
  };

  const handlePlaying = () => {
    setIsShowLoading(false);
  };

  const { mp4, webm, poster, analytic } = video;

  const gameContext = useGameContext();

  const handleEndedVideo = () => {
    if (analytic) {
      const { action, label } = analytic;
      sendEventToCounters({ action, label });
    }

    gameContext?.setShowQuoteMendeleev(true);

    if (currentStepName === 'bottleLoadToMachine') {
      gameContext?.setShowFinishComponent(true);
    }
  };

  return (
    <>
      <video
        preload={isMobile ? 'metadata' : 'none'}
        playsInline
        onEnded={handleEndedVideo}
        // onTimeUpdate={onTimeUpdate}
        onWaiting={handleWaiting}
        onPlaying={handlePlaying}
        poster={poster}
        width="100%"
        height="100%"
      >
        {isBrowserEdge || isBrowserSafari ? (
          <>
            {mp4 && (
              <source
                src={mp4}
                type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
              />
            )}
            {webm && (
              <source src={webm} type='video/webm;codecs="vp8, vorbis"' />
            )}
          </>
        ) : (
          <>
            {webm && (
              <source src={webm} type='video/webm;codecs="vp8, vorbis"' />
            )}
            {mp4 && (
              <source
                src={mp4}
                type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
              />
            )}
          </>
        )}
      </video>

      {isShowLoading && <Loader />}
    </>
  );
};
