import cn from 'classnames';
import styles from './Processing.module.scss';
import { useGameContext } from '@/src/pages/Game';
import { useEffect, useState } from 'react';
// import processingBg from './images/processing.jpg';
import compounding from './images/compounding.png';
import drying from './images/drying.png';
import mixing from './images/mixing.png';
import sendEventToCounters from '@/src/counterEvents';

const steps = ['drying', 'mixing', 'compounding'];

export const Processing = () => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const gameContext = useGameContext();

  const handleChoice = (name: string) => {
    if (selectedItems.includes(name)) {
      if (selectedItems.indexOf(name) === selectedItems.length - 1) {
        setSelectedItems((prev) => prev.filter((p) => p !== name));
      }
    } else {
      setSelectedItems((prev) => [...prev, name]);
    }
  };

  const getIndexItem = (name: string) => {
    return selectedItems.indexOf(name) !== -1
      ? selectedItems.indexOf(name) + 1
      : '';
  };

  useEffect(() => {
    let nextStepName = '';
    if (selectedItems.length >= 3) {
      if (
        selectedItems[0] === steps[0] &&
        selectedItems[1] === steps[1] &&
        selectedItems[2] === steps[2]
      ) {
        nextStepName = 'processingCorrect';
        gameContext?.setCorrectOrder(true);
      } else {
        nextStepName = 'processingWrong';
        gameContext?.setCorrectOrder(false);
      }

      sendEventToCounters({
        action: 'video3',
        label:
          nextStepName === 'processingCorrect'
            ? 'correct-sequence'
            : 'incorrect-sequence',
      });

      gameContext?.setCurrentStepName(nextStepName);
      gameContext?.changeVideoTab(nextStepName);
    }
  }, [selectedItems]);

  return (
    <div className={cn('choice', styles.wrapper)}>
      {/* <img src={processingBg} alt="" className="choice_bg" /> */}
      <div className={cn(styles.wrap, styles.wrap_1)}>
        {getIndexItem(steps[2]) && (
          <span className={styles.number}>{getIndexItem(steps[2])}</span>
        )}
        <div
          className={cn(styles.element, styles.element_1, 'choice_element')}
          onClick={() => handleChoice(steps[2])}
        >
          <img src={compounding} alt="" />
        </div>
        <div className={styles.descr}>
          <p className={styles.descr_title}>Компаундирование</p>
          <p className={styles.descr_text}>
            введение добавок и&#160;наполнителей для улучшения механических
            свойств конечного полимера
          </p>
        </div>
      </div>
      <div className={cn(styles.wrap, styles.wrap_2)}>
        {getIndexItem(steps[0]) && (
          <span className={styles.number}>{getIndexItem(steps[0])}</span>
        )}
        <div
          className={cn(styles.element, styles.element_2, 'choice_element')}
          onClick={() => handleChoice(steps[0])}
        >
          <img src={drying} alt="" />
        </div>
        <div className={styles.descr}>
          <p className={styles.descr_title}>Мытье и сушка</p>
          <p className={styles.descr_text}>
            очищение и&#160;удаление влаги с&#160;поверхности полимера перед его
            переработкой
          </p>
        </div>
      </div>
      <div className={cn(styles.wrap, styles.wrap_3)}>
        {getIndexItem(steps[1]) && (
          <span className={styles.number}>{getIndexItem(steps[1])}</span>
        )}
        <div
          className={cn(styles.element, styles.element_3, 'choice_element')}
          onClick={() => handleChoice(steps[1])}
        >
          <img src={mixing} alt="" />
        </div>
        <div className={styles.descr}>
          <p className={styles.descr_title}>Смешивание</p>
          <p className={styles.descr_text}>
            процесс соединения двух и&#160;более полимеров для создания
            материала с&#160;нужным перечнем свойств
          </p>
        </div>
      </div>
    </div>
  );
};
