import cn from 'classnames';

// import styles from './VoteLink.module.scss';

type TProps = {
  text?: string;
  classNames?: string[];
  onClick?: () => void;
};

export const VoteLink = ({ text, classNames, onClick }: TProps) => {
  return (
    <a
      className={cn(classNames?.join(' '), 'btn')}
      href="https://rating.hh.ru/poll/?utm_source=sibur&utm_medium=referral&utm_campaign=special_project"
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      {text}
    </a>
  );
};
